import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import pathnames from "routes/pathnames";
import AppButtonImage from "components/app-button-image";
import { resetMotor } from "store/slices/motor";
//import car_logo from "assets/images/pages/page-manual-type/Car.png";
//import car2_logo from "assets/images/pages/page-manual-type/Car2.png";
import car3_logo from "assets/images/pages/page-manual-type/Car3.png";
import pickup_truck_logo from "assets/images/pages/page-manual-type/Pickup_Truck.png";
import semi_truck_logo from "assets/images/pages/page-manual-type/Semi_Truck.png";
//import motorbike_logo from "assets/images/pages/page-manual-type/Motorcycle.png";
import motorbike2_logo from "assets/images/pages/page-manual-type/Motor2.png";
import const_truck_logo from "assets/images/pages/page-manual-type/Const_Truck.png";

const PageManualType = () => {
	const timer = useRef();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const onHandleNavigateManualMotor = (name) => {
		//console.log("value", manual?.referralCode)
		sessionStorage.setItem('fromType', 'true');
		navigate(pathnames.manualEnquiry, {
			state: {type: name} 
		});
	};

	const onHandleNavigateNormalEnquiry = (name) => {
		navigate(pathnames.enquiry, {
			state: {type: name} 
		});
	};

	useEffect(() => {
		timer.current = setTimeout(() => {
			dispatch(resetMotor());
		}, 100);

		return () => {
			clearTimeout(timer.current);
		};
	}, [dispatch]);
	
	useEffect(() => {
		// Check if the session flag is set
		const fromHome = sessionStorage.getItem('fromHome');
		const fromtype = sessionStorage.getItem('fromType');
	
		if (!fromHome && !fromtype) {
		  // If no session flag, redirect to home page0
		  navigate(pathnames.home, { replace: true });
		}
	
		// Add event listener to handle page refresh
		const handleBeforeUnload = () => {
		  sessionStorage.removeItem('fromHome');
		  sessionStorage.removeItem('fromType');
		};
	
		window.addEventListener('beforeunload', handleBeforeUnload);
	
		// Cleanup the event listener on component unmount
		return () => {
		  window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, [navigate]);

	return (
		<div className="page-motor-type">
			<div className="home">
				<main className="main">
						<div className="main__form">
							<div className="main__button-container">
								<AppButtonImage icon={car3_logo} className="button" type="button" name="car" label="Car" onClick={() => onHandleNavigateNormalEnquiry('Car')} descript="Including SUV, MPV, Pickups" />
								<AppButtonImage icon={motorbike2_logo} className="button" type="button" name="motorbike" label="Motorbike" onClick={() => onHandleNavigateManualMotor('Motorbike')} descript="Including Superbike" />
								<AppButtonImage icon={semi_truck_logo} className="button" type="button" name="APermit" label="A Permit" onClick={() => onHandleNavigateManualMotor('APermit')} descript="For Logistic & Transportation" />
								<AppButtonImage icon={pickup_truck_logo} className="button" type="button" name="CPermit" label="C Permit" onClick={() => onHandleNavigateManualMotor('CPermit')} descript="Goods Carrying Lorry & Van" />
								<AppButtonImage icon={const_truck_logo} className="button" type="button" name="SType" label="Special Type" onClick={() => onHandleNavigateManualMotor('SpecialType')} descript="For Constrution Truck" />
							</div>
					</div>
				</main>
			</div>
		</div>
	);
};

export default PageManualType;
