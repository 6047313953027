import axios from "axios";

const services = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	headers: {
		"Content-Type": "application/json",
	},
});

const services_2 = axios.create({
	baseURL: process.env.REACT_APP_API_URL_2,
	headers: {
		"Content-Type": "application/json",
	},
});

const services_3 = axios.create({
	baseURL: process.env.REACT_APP_API_URL_2,
	headers: {
		'Content-Type': 'multipart/form-data',
	},
});

export default services;
export { services_2, services_3 };
