import COMMON from "common";
import services, {services_2, services_3} from "services";
import encodeQueryParams from "common/encode-query-params";

const api = {
	get: {
		general: {
			list: (params) => services.get(COMMON.ENDPOINT_PATH.GENERAL.LIST + encodeQueryParams(params), { header: { token: false } }),
			states: (params) => services.get(COMMON.ENDPOINT_PATH.GENERAL.STATES + encodeQueryParams(params)),
			cities: (params) => services.get(COMMON.ENDPOINT_PATH.GENERAL.CITIES + encodeQueryParams(params)),
			postcode: (params) => services.get(COMMON.ENDPOINT_PATH.GENERAL.POSTCODE + encodeQueryParams(params)),
			listing: (params) => services_2.get(COMMON.ENDPOINT_PATH.GENERAL.LISTING + encodeQueryParams(params)),
		},
		motor: {
			paymentStatus: (payload) => services.get(COMMON.ENDPOINT_PATH.INSURER.PAYMENT_STATUS + encodeQueryParams(payload), { cancelId: COMMON.ENDPOINT_PATH.INSURER.PAYMENT_STATUS }),
		},
	},
	post: {
		motor: {
			quotation: (payload) => services.post(COMMON.ENDPOINT_PATH.INSURER.QUOTATION, payload),
			updateQuotation: (payload) => services.post(COMMON.ENDPOINT_PATH.INSURER.UPDATE_QUOTATION, payload),
			regenerateQuotation: (payload) => services.post(COMMON.ENDPOINT_PATH.INSURER.REGENERATE_QUOTATION, payload),
			recalculateQuotation: (payload) => services.post(COMMON.ENDPOINT_PATH.INSURER.RECALCULATE_QUOTATION, payload),
			payment: (payload) => services.post(COMMON.ENDPOINT_PATH.INSURER.PAYMENT_CHECKOUT, payload),
			confirm: (payload) => services.post(COMMON.ENDPOINT_PATH.INSURER.CONFIRM_QUOTATION, payload),
			manualEnquiry: (payload) => services_3.post(COMMON.ENDPOINT_PATH.INSURER.MANUAL_ENQUIRY , payload),
		},
	},
	patch: {},
	put: {},
};

export default api;
