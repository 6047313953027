const pathnames = {
	home: "/",
	enquiry: "/motor/enquiry",
	quotation: "/motor/quotation",
	applicant: "/motor/applicant",
	summary: "/motor/summary",
	receipt: "/motor/receipt",
	appStore: "/motor/appStore",
	motorType: "/motor/type",
	manualEnquiry: "/motor/manualEnquiry",
	manualEnquiryReceipt: "/motor/mreceipt",
	
};

export default pathnames;
