import React, { useEffect, useMemo, useCallback, Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

//import COMMON from "common";
//import api from "services/api";
import pathnames from "routes/pathnames";
import classNames from "common/class-names";
import { formatDatePattern } from "common/calendar";
//import serveRequestErrors from "common/serve-request-errors";
import AppButton from "components/app-button";
import AppCardStatus from "components/pages/page-enquiry/app-card-status";
import step1Icon from "assets/images/pages/page-receipt/step1-icon.webp";
import step2Icon from "assets/images/pages/page-receipt/step2-icon.webp";
import step3Icon from "assets/images/pages/page-receipt/step3-icon.webp";
import step4Icon from "assets/images/pages/page-receipt/step4-icon.webp";
import paymentSuccessIcon from "assets/images/pages/page-receipt/payment-success-icon.webp";
import appleAppStoreIcon from "assets/images/pages/page-app-store/apple-app-store-icon.svg";
import googleAppStoreIcon from "assets/images/pages/page-app-store/google-app-store-icon.svg";
import huaweiAppStoreIcon from "assets/images/pages/page-app-store/huawei-app-store-icon.svg";

const PageReceipt = (props) => {
	//const dispatch = useDispatch();
	const navigate = useNavigate();
	const manual = useSelector((state) => state.manual);
	const [messageType] = useState("");

	const onHandleNavigateHome = () => {
		navigate(pathnames.home);
	};

	const onHandleNavigateDownload = useCallback(() => {
		navigate(pathnames.appStore);
	}, [navigate]);

	const onHandleNavigateAppIos = () => {
		window.open(process.env.REACT_APP_APP_STORE);
	};

	const onHandleNavigateAppAndroid = () => {
		window.open(process.env.REACT_APP_PLAY_STORE);
	};

	const onHandleNavigateAppHuawei = () => {
		window.open(process.env.REACT_APP_GALLERY);
	};

	//prettier-ignore
	const totalReceiptData = useMemo(() => ({
        order:[
            { label: "Reference Number", value: manual?.result?.referenceNumber || "", bold: true },
            { label: "Submission Date", value: formatDatePattern(new Date()), bold: true },
			{ label: "Referral Code", value: manual?.referralCode || "", bold: true },        
		],
	}), [manual]);

	const className = useCallback((obj) => {
		return classNames({
			"quotation-list__text": true,
			"quotation-list__description": obj?.bold,
		});
	}, []);

	const headerClassName = useCallback((obj) => {
		return classNames({
			"quotation-list__description": true,
			"quotation-list__description--value": obj?.bold,
		});
	}, []);

	useEffect(() => {
		// Check if the session flag is set
		const fromME = sessionStorage.getItem('fromME');
	
		if (!fromME) {
		  // If no session flag, redirect to home page
		  navigate(pathnames.home, { replace: true });
		}
	
		// Add event listener to handle page refresh
		const handleBeforeUnload = () => {
		  sessionStorage.removeItem('fromME');
		};
	
		window.addEventListener('beforeunload', handleBeforeUnload);
	
		// Cleanup the event listener on component unmount
		return () => {
		  window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	  }, [navigate]);

	return (
		<div className="page-receipt">
			<div className="receipt">
				{!messageType && (
					<Fragment>
						<div className="receipt__body">
							<img className="receipt__icon" src={paymentSuccessIcon} alt="payment success" />
							<p className="receipt__status">Submit Successfully!</p>

							<div className="receipt__container">
								<div className="receipt__wrapper">
									<p className="receipt__title">Submission Information</p>
								</div>

								<div className="receipt__wrapper">
									<div className="receipt-form__container">
										<div className="receipt-form__header">
											{totalReceiptData.order.map((o) => {
												return (
													<ul className="header-quotation-list" key={o.label}>
														<li className="header-quotation-list__item">
															<p className={className()}>{o.label}</p>
															<p className={headerClassName({ bold: o.bold, value: true })}>{o.value}</p>
														</li>
													</ul>
												);
											})}
										</div>


										<div className="receipt-form__divider" />

										<div className="receipt-form__footer">
											<p className="receipt-form__description">Thank you for submitting an application with easycover.</p>
											<p className="receipt-form__description">We will notify you via email on your application process. </p>
										</div>
									</div>
								</div>
							</div>

							<div className="step__body">
								<div className="step__wrapper">
									<p className="step__text">Download our mobile app to view policy details. To get started, please follow these simple steps:</p>
								</div>

								<div className="step__wrapper">
									<div className="step__box">
										<p className="step__title">Step 1</p>

										<div className="step__content">
											<img src={step1Icon} alt="step1" />
											<p className="step__text">Download ‘easycover.my’ app from App Store, Google Play, App Gallery or click the link to download the app.</p>
										</div>
									</div>

									<div className="step__box">
										<p className="step__title">Step 2</p>

										<div className="step__content">
											<img src={step2Icon} alt="step2" />
											<p className="step__text">Login or create an acocunt using the same email address that submitted this application</p>
										</div>
									</div>

									<div className="step__box">
										<p className="step__title">Step 3</p>

										<div className="step__content">
											<img src={step3Icon} alt="step3" />
											<p className="step__text">Tap on the My Coverage.</p>
										</div>
									</div>

									<div className="step__box">
										<p className="step__title">Step 4</p>

										<div className="step__content">
											<img src={step4Icon} alt="step4" />
											<p className="step__text">Tap to view policy details.</p>
										</div>
									</div>
								</div>

								<div className="step__container">
									<p className="step__sub-title">Step 1: Download the Mobile App</p>

									<div className="step__button-container">
										<button type="button" className="step__button" onClick={onHandleNavigateAppIos}>
											<img src={appleAppStoreIcon} alt="apple" />
										</button>

										<button type="button" className="step__button" onClick={onHandleNavigateAppAndroid}>
											<img src={googleAppStoreIcon} alt="google" />
										</button>

										<button type="button" className="step__button" onClick={onHandleNavigateAppHuawei}>
											<img src={huaweiAppStoreIcon} alt="huawei" />
										</button>
									</div>
								</div>

								<div className="step__container">
									<p className="step__sub-title">Step 2: Create your account</p>
									<p className="step__sub-text">Once the app is installed, open it and tap on “Login/Sign up” and tap on “Sign up” on pop up screen.</p>

									<ul className="step-list">
										<li className="step-list__item">Please sign up with your personal email address and relevant details.</li>
										<li className="step-list__item">Complete the verification process to confirm your account.</li>
									</ul>
								</div>

								<div className="step__container">
									<p className="step__sub-title">Step 3: Explore Your Benefits</p>
									<p className="step__sub-text">
										Congratulations! You are now set up to enjoy the full range of benefits, including insurance coverage. Inside the app, you can easily view your insurance policy details, coverage limits, claim
										procedures, and more.
									</p>
								</div>
							</div>
						</div>

						<div className="receipt__button-container">
							<AppButton outline type="button" label="Back to home" onClick={onHandleNavigateHome} />
							<AppButton type="button" label="Download app" onClick={onHandleNavigateDownload} />
						</div>
					</Fragment>
				)}
				{messageType && (
					<div className="receipt__body">
						<AppCardStatus messageType={messageType} />
					</div>
				)}
			</div>
		</div>
	);
};

export default PageReceipt;
