import React, { useMemo, useCallback, useState, Fragment } from "react";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { compose } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";

import COMMON from "common";
import classNames from "common/class-names";
import { formatDatePattern } from "common/calendar";
import serveRequestErrors from "common/serve-request-errors";
import { formatCurrency } from "common/format-currency-pattern";
import { getDateOfBirthAge } from "common/get-date-of-birth-age";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppCardInfo from "components/app-card-info";
import AppCardAccordion from "components/pages/page-summary/app-card-accordion";
import withAppTimeout from "contexts/with-app-timeout";
import api from "services/api";
import pathnames from "routes/pathnames";
import referralIcon from "assets/images/pages/page-summary/referral-icon.svg";

const PageSummary = (props) => {
	const navigate = useNavigate();
	const motor = useSelector((state) => state.motor);
	const enquiryInfo = useMemo(() => motor?.enquiry, [motor?.enquiry]);
	const applicantInfo = useMemo(() => motor?.applicant, [motor?.applicant]);
	const quotationInfo = useMemo(() => motor?.quotation, [motor?.quotation]);
	const referralCode = useMemo(() => motor?.referralCode || "", [motor?.referralCode]);
	const vehicleOwner = useMemo(() => quotationInfo?.vehicleOwner, [quotationInfo?.vehicleOwner]);
	//prettier-ignore
	const quotationCheckout = useMemo(() => quotationInfo?.quotationCheckout?.filter((o) => { return  o.status !== COMMON.MESSAGE_TYPE.FAILED }), [quotationInfo?.quotationCheckout]);
	const [isSubmitting, setIsSubmitting] = useState(false);

	//prettier-ignore
	const selectedQuotation = useMemo(() => quotationCheckout?.find((o) => { return o.insurer === motor.quotation?.provider; }),[motor.quotation?.provider, quotationCheckout]);
	const quotationNvic = selectedQuotation?.nvic?.find((o) => o.nvic === quotationInfo?.selectedNVIC);

	const formik = useFormik({
		initialValues: { referralCode: referralCode },
	});

	const onHandleNavigateApplicant = () => {
		navigate(pathnames.applicant);
	};

	const onHandleSubmitPayment = async () => {
		let confirmResponse = null;

		try {
			let paymentPayload = {
				quotationId: quotationInfo?.quotationId,
				paymentAmount: selectedQuotation?.totalPayable,
				redirectURL: window.location.origin + pathnames.receipt,
			};

			confirmResponse = await api.post.motor.payment(paymentPayload);

			window.location.replace(confirmResponse?.paymentUrl);
		} catch (error) {
			serveRequestErrors(error);
		} finally {
			setIsSubmitting(false);
		}
	};

	const onHandleSubmitConfirm = async () => {
		let response = null;

		setIsSubmitting(true);
		const selectedQuotation = quotationCheckout?.find((o) => {
			return o.insurer === quotationInfo?.provider;
		});

		const benefitPayload = selectedQuotation?.selectedExtraBenefit?.length ? selectedQuotation?.selectedExtraBenefit : quotationInfo?.extraBenefitList;

		const isAdditionalDriver = benefitPayload?.some((i) => i?.benefitCode === COMMON.EXTRA_BENEFITS.ADDITIONAL_DRIVERS);

		try {
			let payload = {
				identificationType: enquiryInfo?.identificationType,
				identificationNo: enquiryInfo?.identificationNo,
				quotationId: quotationInfo?.quotationId,
				nvic: quotationInfo?.selectedNvicCode,
				provider: quotationInfo?.provider,
				sumInsured: selectedQuotation?.sumInsured,
				extraBenefitList: benefitPayload,
				additionalDriverList: isAdditionalDriver ? quotationInfo?.additionalDriverList : null,
				applicantInformation: {
					name: applicantInfo.name,
					email: applicantInfo.email,
					nric: applicantInfo.nric,
					age: getDateOfBirthAge(applicantInfo.birthDate),
					birthDate: applicantInfo.birthDate,
					mobileNo: applicantInfo.prefixNo + applicantInfo.mobile,
					gender: applicantInfo.gender,
					maritalStatus: applicantInfo.maritalStatus,
					address1: applicantInfo.correspondenceAddressLine1,
					address2: applicantInfo.correspondenceAddressLine2 ?? "",
					state: applicantInfo.state,
					city: applicantInfo.city,
					postcode: applicantInfo.postcode,
					officeNo: applicantInfo.office ? applicantInfo.officePrefixNo + applicantInfo.office : "",
					mobile: applicantInfo.mobile,
					prefixNo: applicantInfo.prefixNo,
					officePrefixNo: applicantInfo.officePrefixNo,
					office: applicantInfo.office,
					occupation: applicantInfo.occupation,
					experience: applicantInfo.experience,
				},
				agreedValue: quotationInfo.agreedValue,
				referralCode: formik.values.referralCode,
				vehicleBody: quotationInfo.vehicleBody,
			};

			response = await api.post.motor.confirm(payload);
		} catch (error) {
			serveRequestErrors(error);
		} finally {
			setIsSubmitting(false);
		}

		if (response) {
			onHandleSubmitPayment();
		}
	};

	const residentialAddress = useMemo(() => {
		let address = [];

		if (!vehicleOwner?.address) return "";

		address.push(vehicleOwner.address?.addressLine1);

		if (vehicleOwner.address?.addressLine2) address.push(vehicleOwner?.address?.addressLine2);

		address.push(vehicleOwner.address?.postcode);

		address.push(vehicleOwner?.city);

		address.push(vehicleOwner.address?.state);

		return address.filter((o) => o).join(", ");
	}, [vehicleOwner]);

	//prettier-ignore
	const VehicleData = useMemo(() => ({
		title: "Vehicle Details",
		info: [
			{ label: "Registration No.", value: selectedQuotation?.registrationNumber ?? "-" },
			{ label: "Model", value: quotationNvic?.modelDescription ?? "-" },
			{ label: "Vehicle Body", value: quotationInfo?.vehicleBody ?? "-" },
			{ label: "Make Year", value: selectedQuotation?.makeYear ?? "-" },
			{ label: "Engine Displacement (CC)", value: quotationNvic?.capacity ?? "-" },
			{ label: "Chassis No.", value: selectedQuotation?.chassisNumber ?? "-" },
			{ label: "NVIC Code", value: quotationNvic?.nvic ?? "-" },
			{ label: "Engine No.", value: selectedQuotation?.engineNumber ?? "-" },
			{ label: "Seat", value: quotationNvic?.seat ?? "-" },
			{ label: "Transmission", value: quotationNvic?.transmissionType ?? "-" },
			{ label: "Build Type", value: selectedQuotation?.buildType ?? "-" },
			{ label: "No Claim Discount (NCD) Percentage", value: `${selectedQuotation?.noClaimDiscount ?? 0}%` },
			{ label: "ISM Market Value", value: quotationNvic?.ismMarketValue ? `RM${formatCurrency(quotationNvic?.ismMarketValue ?? 0)}`: "-" },
		],
	}), [selectedQuotation, quotationInfo, quotationNvic]);

	//prettier-ignore
	const ApplicantData = useMemo(() => ({
		title: "Applicant Details",
		info: [
			{ label: "Name", value: vehicleOwner?.name },
			{ label: enquiryInfo?.identificationType === COMMON.IDENTIFICATION_TYPE.NRIC ? "NRIC No." : "Passport" , value: vehicleOwner?.nric},
			{ label: "Date of Birth", value: formatDatePattern(new Date(vehicleOwner?.dateOfBirth)) },
			{ label: "Gender", value: vehicleOwner?.gender },
			{ label: "Marital Status", value: vehicleOwner?.maritalStatusDescription },
			{ label: "Email", value: vehicleOwner?.email },
			{ label: "Office No.", value: isNaN(vehicleOwner?.officeNumber) ? `${vehicleOwner?.officeNumber}` : "N/A" },
			{ label: "Mobile No.", value: vehicleOwner?.mobileNumber },
			{ label: "Residential Address", value: residentialAddress  },
		],
	}), [vehicleOwner, residentialAddress, enquiryInfo]);

	const benefitList = useMemo(() => {
		let selectedExtraBenefit = selectedQuotation?.selectedExtraBenefit || [];

		selectedExtraBenefit = selectedExtraBenefit.map((o) => ({ label: o?.benefitDescription, value: `RM${formatCurrency(o?.benefitPremium)}` }));

		selectedExtraBenefit = selectedExtraBenefit.filter((o) => {
			if (o?.label === "Additional Driver") {
				return { ...o, label: `Additional ${selectedQuotation?.additionalDriverList?.length} Driver` };
			} else {
				return o;
			}
		});

		const newBenefitList = [{ label: "Additional Benefits", value: "", bold: true }].concat(selectedExtraBenefit);

		return newBenefitList;
	}, [selectedQuotation]);

	//prettier-ignore
	const totalQuotationData = useMemo(() => ({
		info: [
			{ label: "Selected Insurer", value: quotationInfo?.provider },
			{ label: "Sum Insured", value: `RM${formatCurrency(selectedQuotation?.sumInsured)}`, bold: true },
			{ label: "Basic Premium", value: `RM${formatCurrency(selectedQuotation?.basicPremium)}` },
			{ label: `No Claim Discount (NCD) ${selectedQuotation?.ncdPercentage}%`, value: `- RM${formatCurrency(selectedQuotation?.ncdAmount)}` },
			{ label: "Premium Net of NCD", value: `RM${formatCurrency(selectedQuotation?.premiumNetNcd)}`, bold: true },
			],
		benefits: benefitList,
        last:[
            { label: "Gross Premium", value: `RM${formatCurrency(selectedQuotation?.grossPremium)}`, bold: true },
			{ label: "Service Tax (8%)", value: `RM${formatCurrency(selectedQuotation?.serviceTax)}` },
			{ label: "Stamp Duty Fee", value: `RM${formatCurrency(selectedQuotation?.stampDutyFee)}` },
			{ label: "Road Tax", value: `RM${formatCurrency(selectedQuotation?.roadTax)}` },
        ],
        totalPayable: [
            { label: "Total Payable:", value: `RM${formatCurrency(selectedQuotation?.totalPayable)}`, bold: true }
		],
        commissionInfo: [
            { label: `10% commission fee amounting to RM${formatCurrency(selectedQuotation?.agentCommissionAmount)} is payable to VKA Wealth Planners.` }
        ]
	}), [selectedQuotation, quotationInfo?.provider, benefitList]);

	//prettier-ignore
	const DriverData = useCallback((obj) => {
		const isNRIC = obj?.response?.identificationType === COMMON.IDENTIFICATION_TYPE.NRIC;
		return {
			title: `Additional ${COMMON.NUMBERS[obj?.i]} Driver`,
			info: [
				{ label: "Name", value: obj?.response?.name },
				{ label: isNRIC ? "NRIC No." : "Passport" , value: obj?.response?.identificationNumber},
				{ label: "Date of Birth", value: formatDatePattern(new Date(obj?.response?.dateOfBirth)) },
				{ label: "Gender", value: obj?.response?.gender },
				{ label: "Occupation", value: obj?.response?.occupationDescription },
				{ label: "Marital status", value: obj?.response?.maritalStatusDescription },
				{ label: "Relationship", value: obj?.response?.relationDescription },
				{ label: "Driver experience", value: `${obj?.response?.experience} years` },
				{ label: "Email", value: obj?.response?.email },
				{ label: "Mobile No.", value: obj?.response?.mobileNo },
			]}
	},[]);

	const className = useCallback((obj) => {
		return classNames({
			"quotation-list__text": true,
			"quotation-list__description": obj?.bold,
		});
	}, []);

	const valueClassName = useCallback((obj) => {
		return classNames({
			"quotation-list__text": !obj?.bold,
			"quotation-list__text--value": !obj?.bold,
			"quotation-list__description": obj?.bold,
			"quotation-list__description--value": obj?.bold,
		});
	}, []);

	return (
		<div className="page-summary">
			<div className="summary">
				<div className="summary__form">
					<div className="summary__header">
						<p className="summary__title">Quotation Summary</p>
						{/*prettier-ignore*/}
						<p>Your quotation is about to expire in <b>{props.timer}</b></p>
					</div>

					<div className="summary__container">
						<div className="summary__wrapper">
							<AppCardInfo data={VehicleData} />

							<AppCardInfo data={ApplicantData} />

							{selectedQuotation?.additionalDriverList &&
								selectedQuotation?.additionalDriverList?.map((o, i) => {
									const infoData = DriverData({ response: o, i });

									return <AppCardAccordion data={infoData} key={i} />;
								})}
						</div>

						<div className="summary__wrapper">
							<div className="total-quotation">
								<div className="total-quotation__container">
									<div className="total-quotation__wrapper">
										<p className="total-quotation__title">Total Quotation</p>
									</div>

									<div className="total-quotation__wrapper">
										<div className="total-quotation__form">
											{Object.keys(totalQuotationData).map((obj, index) => {
												const quotation = totalQuotationData[obj];
												const notLastItem = index + 1 !== Object.keys(totalQuotationData).length;
												const noBenefit = obj === "benefits" && selectedQuotation?.selectedExtraBenefit.length === 0;
												const isCommissionInfo = obj === "commissionInfo";

												return (
													<Fragment key={index}>
														{!noBenefit &&
															quotation?.map((o) => {
																if (isCommissionInfo) return <div className="total-quotation__info-text">{o?.label}</div>;

																return (
																	<ul className="quotation-list" key={o?.label}>
																		<li className="quotation-list__item">
																			<p className={className({ bold: o?.bold })}>{o?.label}</p>
																			<p className={valueClassName({ bold: o?.bold, value: true })}>{o?.value}</p>
																		</li>
																	</ul>
																);
															})}

														{notLastItem && !noBenefit && <div className="total-quotation__divider" />}
													</Fragment>
												);
											})}
										</div>
									</div>
								</div>
							</div>

							<div className="referral">
								<div className="referral__container">
									<div className="referral__box">
										<div className="referral__wrapper">
											<img className="referral__logo" src={referralIcon} alt="logo" />
											<p className="referral__label">Referral Code</p>
										</div>

										<div className="referral__value">
											{/*prettier-ignore*/}
											<AppInput type="text" name="referralCode" label="" placeholder="Enter Referral Code" value={formik.values.referralCode} error={formik.errors.referralCode} touched={formik.touched.referralCode} onChange={formik.handleChange} disabled={isSubmitting} />
										</div>
									</div>
								</div>
							</div>

							<div className="summary__button-container">
								<AppButton outline type="button" label="Back" onClick={onHandleNavigateApplicant} disabled={isSubmitting} />
								<AppButton type="button" label="Continue" onClick={onHandleSubmitConfirm} disabled={isSubmitting} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default compose(withAppTimeout)(PageSummary);
