import api from "services/api";
import serveRequestErrors from "common/serve-request-errors";

const getIdentificationListing = async () => {
	let response = null;
	let errorMessage = null;

	try {
		const params = { lookupID: "ENTITY_TYPE"};
		const res = await api.get.general.listing(params);

		// Check if res is an array or if the data is nested within an object
        const data = Array.isArray(res) ? res : res.data || [];

        if (!Array.isArray(data)) {
          throw new Error("Unexpected response format: data is not an array");
        }
    
        const content = data.map(({ title, value, ...o }) => ({
          label: title,
          value: value,
          ...o,
        }));
		response = { content };
	} catch (error) {
		errorMessage = serveRequestErrors(error);
	}

	if (response) return response;

	throw errorMessage;
};

export default getIdentificationListing;
