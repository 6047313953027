import React, { useEffect, useRef, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import { useFormik } from "formik";
import pathnames from "routes/pathnames";
import AppButton from "components/app-button";
import { resetMotor } from "store/slices/motor";
import { resetManualMotor } from "store/slices/manual";
import logo from "assets/images/logo.webp";
import AppInput from "components/app-input";
import referralIcon from "assets/images/pages/page-summary/referral-icon.svg";
import { setManualReferralCode } from "store/slices/manual";
import { setReferralCode } from "store/slices/motor";
import Turnstile from 'react-turnstile';

const PageHome = () => {
	const timer = useRef();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const referralCode = urlParams.get("myReferralCode");
	const [turnstileToken, setTurnstileToken] = useState(null);
	const sitekey = process.env.REACT_APP_TURNSTILE_SITE_KEY;

	const initialValues = useMemo(() => {
		return {
		  refCode: referralCode || "",
		};
	  }, [referralCode]);

	const formik = useFormik({
		initialValues,
		onSubmit: async (values) => {
			await onHandleSubmit(values);
		},
	});

	const onHandleNavigateMotor = () => {
		sessionStorage.setItem('fromHome', 'true');
		navigate(pathnames.motorType);
	};

	const onHandleSubmit = async (values) => {
		if (values.refCode && values.refCode !== "null"){
			await Promise.all([
				dispatch(setManualReferralCode(values.refCode)),
				dispatch(setReferralCode(values.refCode))
			  ]);
		} 

		console.log("refcode", values.refCode);
		onHandleNavigateMotor();
	};

	useEffect(() => {
		timer.current = setTimeout(() => {
			dispatch(resetMotor());
			dispatch(resetManualMotor());
		}, 100);

		return () => {
			clearTimeout(timer.current);
		};
	}, [dispatch]);

	return (
		<div className="page-home">
			<div className="home">
				<main className="main">
					<div className="main__content main__content--background">
						<div className="main__logo">
							<img src={logo} alt="logo" />
						</div>
					</div>

					<div className="main__content main__content--form">
						<div className="main__form">
							<h1 className="main__title">Insure and secure your Motor with easycover.my</h1>
							<p className="main__description">Ready to renew your car insurance? </p>
							<p className="main__description">Have it delivered right at your doorstep with easycover.my.</p>
							<p className="main__description">Get FREE quotation now!</p>

							<div className="referral">
									<div className="referral__container">
										<div className="referral__box">
											<div className="referral__wrapper">
												<img className="referral__logo" src={referralIcon} alt="logo" />
												<p className="referral__label">Referral Code</p>
											</div>
								
											<div className="referral__value">
												{/*prettier-ignore*/}
												<AppInput type="text" name="refCode" label="" placeholder="Enter Referral Code" value={formik.values.refCode} error={formik.errors.refCode} touched={formik.touched.refCode} onChange={formik.handleChange} disabled={formik.isSubmitting || Boolean(referralCode)} />
											</div>

											
										</div>
									</div>
								</div>

							<div className="main__button-container">
								<Turnstile className="main__button-container__turnstile" sitekey={sitekey} onVerify= {(token) => setTurnstileToken(token)} theme="dark"/>
								<AppButton type="button" label="Let's get started" onClick={formik.handleSubmit} disabled={formik.isSubmitting || !turnstileToken } />
							</div>
						</div>
					</div>
				</main>
			</div>
		</div>
	);
};

export default PageHome;
