import React, { memo, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import classNames from "common/class-names";

const FileUpload = ({
  id,
  label,
  required,
  disabled,
  multiple,
  maxFiles,
  onFilesChange,
  touched,
  error: propError,
  sampleFiles,
  files // Files from Redux state
}) => {
  const fileInputRef = useRef(null);

  const handleFileUpload = useCallback((event) => {
    const newFiles = Array.from(event.target.files);
    const updatedFiles = [...files, ...newFiles];
    
    if (updatedFiles.length > maxFiles) {
      // If limit exceeded, don't update files
      return;
    }
    
    onFilesChange(updatedFiles);
  }, [files, onFilesChange, maxFiles]);

  const removeFile = useCallback((index) => {
    const updatedFiles = files.filter((_, i) => i !== index);
    onFilesChange(updatedFiles);
  }, [files, onFilesChange]);

  const triggerFileInput = useCallback(() => {
    if (files.length >= maxFiles) {
      return;
    }
    fileInputRef.current.click();
  }, [files.length, maxFiles]);

  const chooseFilesButtonClassName = useCallback(() => {
    return classNames({
      "file-upload__choose-button": true,
      "file-upload__choose-button--disabled": disabled || files.length >= maxFiles,
    });
  }, [disabled, files.length, maxFiles]);

  const removeButtonClassName = useCallback(() => {
    return classNames({
      "file-upload__remove-button": true,
      "file-upload__remove-button--disabled": disabled,
    });
  }, [disabled]);

  return (
    <div className="file-upload">
      <div className="file-upload__header">
        {label && (
          <p className="file-upload__label">
            {label}
            {required && <span className="file-upload__required">*</span>}
          </p>
        )}
        <button
          type="button"
          onClick={triggerFileInput}
          disabled={disabled || files.length >= maxFiles}
          className={chooseFilesButtonClassName()}
        >
          Choose Files
        </button>
      </div>

      <input
        ref={fileInputRef}
        type="file"
        id={id || "fileUpload"}
        onChange={handleFileUpload}
        multiple={multiple}
        disabled={disabled}
        className="file-upload__input"
      />

      {sampleFiles && sampleFiles.length > 0 && (
        <div className="file-upload__sample-files">
          <h4 className="file-upload__sample-files-title">Sample Supporting Documents:</h4>
          <ul className="file-upload__sample-files-list">
            {sampleFiles.map((file, index) => (
              <li key={index} className="file-upload__sample-files-item">
                {file}
              </li>
            ))}
          </ul>
        </div>
      )}

      {files.length > 0 && (
        <div className="file-upload__file-list">
          <h3 className="file-upload__file-list-title">Uploaded Documents: {files.length}/{maxFiles}</h3>
          <ul className="file-upload__file-items">
            {files.map((file, index) => (
              <li key={index} className="file-upload__file-item">
                <span className="file-upload__file-name">{file.name}</span>
                <button
                  type="button"
                  onClick={() => removeFile(index)}
                  disabled={disabled}
                  className={removeButtonClassName()}
                >
                  Remove
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}

      {(touched && propError) && (
        <p className="file-upload__error">{propError}</p>
      )}
    </div>
  );
};

FileUpload.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  maxFiles: PropTypes.number,
  onFilesChange: PropTypes.func.isRequired,
  touched: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
  error: PropTypes.string,
  sampleFiles: PropTypes.arrayOf(PropTypes.string),
  files: PropTypes.array,
};

FileUpload.defaultProps = {
  multiple: true,
  disabled: false,
  required: false,
  maxFiles: 5,
  sampleFiles: [],
  files: [],
};

export default memo(FileUpload);