import React, { useMemo, useCallback, Fragment, useState, useEffect } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import COMMON from "common";
import api from "services/api";
import ERRORS from "common/errors";
import pathnames from "routes/pathnames";
import { getDateOfBirthNRIC } from "common/nric-helpers";
import serveRequestErrors from "common/serve-request-errors";
import {setManualEnquiry, setResult, setTempFiles } from "store/slices/manual";
import formatVehicleString from "common/format-vehicle-string";
import formatPassportString from "common/format-passport-string";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppCheckbox from "components/app-checkbox";
import AppSelectInput from "components/app-select-input";
import AppMaskingInput from "components/app-masking-input";
import AppLoading from "components/pages/page-enquiry/app-loading";
import AppCardStatus from "components/pages/page-enquiry/app-card-status";
import FileUpload from "components/app-upload-files";
import AppMobileInput from "components/app-mobile-input";
import getIdentificationListing from "services/get-identification-listing";
import getEntityListing from "services/get-entity-listing";
import getMaritalListing from "services/get-marital-listing";

const PageManualEnquiry = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const manual = useSelector((state) => state.manual);
	const [messageType, setMessageType] = useState("");
	const [errorCode] = useState("");
	const location = useLocation();
	const referralCode = manual?.referralCode || "";
	const type = location.state?.type || "";

	const initialValues = useMemo(() => {
		const info = manual?.manualEnquiry;
		const tempFiles = manual?.tempFiles;
		const isInfoPassport = info?.identificationType === COMMON.IDENTIFICATION_TYPE.PASSPORT;
		

		let payload = {
			category: ['APermit', 'CPermit', 'SpecialType'].includes(type) ? COMMON.CATEGORY.COMPANY : '',
			companyName: "",
			companyReg: "",
			fullName: "",
			identificationType: "NRIC",
			passport: "",
			nric: process.env.REACT_APP_ID,
			marital: "",
			phoneNumber: "",
			prefixNo: "",
			email: "",
			vehicleType: type,
			vehicleRegNo: process.env.REACT_APP_VEHICLE,
			postcode: process.env.REACT_APP_POSTCODE,
			privacy: false,
			tnc: false,
			takaful: false,
			files: [],
		};

		if (info) {
			payload.tnc = true;

			payload.privacy = true;

			if (info.category) payload.category = info.category;
			if (info.companyName) payload.companyName = info.companyName;
			if (info.companyReg) payload.companyReg = info.companyReg;
			if (info.fullName) payload.fullName = info.fullName;

			if (info.identificationType) payload.identificationType = info.identificationType;

			if (info.identificationNo) {
				if (isInfoPassport) {
					payload.passport = info.identificationNo;
				} else {
					payload.nric = info.identificationNo;
				}
			}

			if (info.marital) payload.marital = info.marital;

			if (info.phoneNumber) payload.phoneNumber = info.phoneNumber;

			if (info.prefixNo) payload.prefixNo = info.prefixNo;

			if (info.email) payload.email = info.email;

			if (info.vehicleRegistrationNo) payload.vehicleRegNo = info.vehicleRegistrationNo;

			if (info.postcode) payload.postcode = info.postcode;

			if (info.takaful) payload.takaful = info.takaful;
		}

		if(tempFiles) payload.files = tempFiles;



		return payload;
	}, [manual, type]);

	const formik = useFormik({
		initialValues,
		validationSchema: yup.object({
			category: yup.string().required(ERRORS.REQUIRED),
			companyName: yup.string().when("category", {
				is: (category) => category === "company",
				then: () => yup.string().required(ERRORS.REQUIRED),
				otherwise: () => yup.string().nullable(),
			} ),
			companyReg: yup.string().when("category", {
				is: (category) => category === "company",
				then: () => yup.string().required(ERRORS.REQUIRED),
				otherwise: () => yup.string().nullable(),
			} ),
			fullName: yup.string().when("category", {
				is: (category) => category === "individual",
				then: () => yup.string().required(ERRORS.REQUIRED),
				otherwise: () => yup.string().nullable(),
			} ),
			identificationType: yup.string().when("category", {
				is: (category) => category === "individual",
				then: () => yup.string().required(ERRORS.REQUIRED),
				otherwise: () => yup.string().nullable(),
			} ),
			passport: yup.string().when(["category","identificationType"], {
				is: (category, identificationType) => 
					category === "individual" &&
				 	identificationType === COMMON.IDENTIFICATION_TYPE.PASSPORT,
				then: () => yup.string().required(ERRORS.REQUIRED),
			}),
			nric: yup.string().when(["category","identificationType"], {
				is: (category, identificationType) => 
					category === "individual" &&
					identificationType === COMMON.IDENTIFICATION_TYPE.NRIC,
				then: () => yup.string().required(ERRORS.REQUIRED).min(14, ERRORS.NRIC),
			}),
			marital: yup.string().when("category", {
				is: (category) => category === "individual",
				then: () => yup.string().required(ERRORS.REQUIRED),
				otherwise: () => yup.string().nullable(),
			}),
			phoneNumber: yup.string().required(ERRORS.REQUIRED),
			email: yup.string().required(ERRORS.REQUIRED),
			//vehicleType: yup.string().required(ERRORS.REQUIRED),
			vehicleRegNo: yup.string().required(ERRORS.REQUIRED),
			postcode: yup.number().required(ERRORS.REQUIRED),
			tnc: yup.boolean().oneOf([true], ERRORS.REQUIRED),
			privacy: yup.boolean().oneOf([true], ERRORS.REQUIRED),
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		},
	});

	const isPassport = useMemo(() => formik.values.identificationType === COMMON.IDENTIFICATION_TYPE.PASSPORT, [formik.values.identificationType]);
	const validIdentificationType = useMemo(() => !!formik.values.identificationType, [formik.values.identificationType]);

	const onHandleNavigateManualReceipt = useCallback(() => {
		sessionStorage.setItem('fromME', 'true');
		navigate(pathnames.manualEnquiryReceipt);
	}, [navigate]);

	const onHandleSubmit = async (values) => {
		let response = null;
		

		try {
			const formData = new FormData();
			let payload = {
				category: values.category,
				companyName: values.category === COMMON.CATEGORY.COMPANY ? values.companyName : null,
  				companyReg: values.category === COMMON.CATEGORY.COMPANY ? values.companyReg : null,
  				fullName: values.category === COMMON.CATEGORY.INDIVIDUAL ? values.fullName : null,
  				identificationType: values.category === COMMON.CATEGORY.INDIVIDUAL ? values.identificationType : null,
  				identificationNo: values.category === COMMON.CATEGORY.INDIVIDUAL 
  				  ? (isPassport ? values.passport : values.nric) 
  				  : null,
  				marital: values.category === COMMON.CATEGORY.INDIVIDUAL ? values.marital : null,
  				dateOfBirth: values.category === COMMON.CATEGORY.INDIVIDUAL && !isPassport 
  				  ? getDateOfBirthNRIC(values.nric) 
  				  : null,
				phoneNumber: values.prefixNo + values.phoneNumber,
				email: values.email,
				vehicleType: values.vehicleType,
				vehicleRegistrationNo: values.vehicleRegNo,
				postcode: values.postcode,
				status: "PENDING",
				takaful: values.takaful,
				//files: values.files, // Include files in the payload
			};

			let combinePayload = {
				MotorManual: payload,
				ReferralCode: {referralCode: referralCode},
			}
			
			dispatch(setManualEnquiry(payload));

			formData.append('data', JSON.stringify(combinePayload));
			// Append files
			values.files.forEach((file, index) => {
				formData.append(`files`, file);
			  });


			// Update API call to handle FormData
			response = await api.post.motor.manualEnquiry(formData, {
				headers: {
				  'Content-Type': 'multipart/form-data',
				},
			  });

			console.log("manual state:" , manual)	  

			//response = await api.post.motor.manualEnquiry(combinePayload);            
		}
		catch (error) {
			if (!error?.response?.data?.errorCode) {
				if (error?.code === COMMON.ERRORS.NETWORK_ERROR) {
					setMessageType(COMMON.MESSAGE_TYPE.NETWORK);
				} else if (error?.code === COMMON.ERRORS.BE_BAD_RESPONSE) {
					setMessageType(COMMON.MESSAGE_TYPE.BAD_RESPONSE);
				}
			} else {
				serveRequestErrors(error);
			}
		} 
		finally {
			formik.setSubmitting(false);
		}

		if (response.data) {
			
			dispatch(setResult(response.data));
			dispatch(setTempFiles([])); // Clear temp files after successful submission
			//console.log("Entire manual state:", manual);
			//console.log("Result from manual state:", manual.result);
			//console.log("Reference number from result:", manual.result?.referenceNumber);
			onHandleNavigateManualReceipt();
		}
	};

	//prettier-ignore
	const onHandleTnc = useCallback((event) => {
		const value = event.currentTarget.checked
		formik.setFieldValue("tnc", !value);
	}, [formik]);

	//prettier-ignore
	const onHandleTakaful = useCallback((event) => {
		const value = event.currentTarget.checked
		formik.setFieldValue("takaful", !value);
	}, [formik]);

	//prettier-ignore
	const onHandlePrivacy = useCallback((event) => {
		const value = event.currentTarget.checked
		formik.setFieldValue("privacy", !value);
	}, [formik]);

	//prettier-ignore
	const PrivacyLabel = useCallback(() => {
		return (
			<Fragment>
				I consent to the<a className="enquiry__tnc-pdf" href="https://www.easycover.my/privacy-policy-2/" target="_blank" rel="noreferrer"> Data Protection and Privacy Policy</a>.<span className="enquiry__tnc-required">*</span>
			</Fragment>
		)
	}, []);

	//prettier-ignore
	const TncLabel = useCallback(() => {
		return (
			<Fragment>
				I have read <a className="enquiry__tnc-pdf" href="https://www.easycover.my/terms/" target="_blank" rel="noreferrer">Terms and Conditions</a> together with <a className="enquiry__tnc-pdf" href="https://www.easycover.my/FAQ/" target="_blank" rel="noreferrer">FAQ</a> herein and fully understand and agree with the said Terms and Conditions and FAQ.<span className="enquiry__tnc-required">*</span>
			</Fragment>
		)
	}, []);

	const TakafulLabel = useCallback(() => {
		return (
			<Fragment>
				I'm interested in Takaful product.
			</Fragment>
		)
	}, []);


	useEffect(() => {

		if(sessionStorage.getItem('fromHome'))
		{
			sessionStorage.removeItem('fromHome');
		}

		// Check if the session flag is set
		const fromType = sessionStorage.getItem('fromType');
		const fromCardStatus = sessionStorage.getItem('fromCardStatus');
	
		if (!fromType && !fromCardStatus) {
		  // If no session flag, redirect to home page
		  navigate(pathnames.home, { replace: true });
		}
	
		// Add event listener to handle page refresh
		const handleBeforeUnload = () => {
		  sessionStorage.removeItem('fromType');
		};
	
		window.addEventListener('beforeunload', handleBeforeUnload);
	
		// Cleanup the event listener on component unmount
		return () => {
		  window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	  }, [navigate]);


	const IdentificationField = useCallback((obj) => {
		if (!obj.isPassport && obj.validIdentificationType) {
			/* prettier-ignore */
			return <AppMaskingInput required type="text" name="nric" label="NRIC No." placeholder="e.g. 901010-14-1234" disabled={obj.disabled} value={obj.values.nric} error={obj.errors.nric} touched={obj.touched.nric} onChange={obj.onChange} format={COMMON.MASKING.NRIC} />
		}

		if (obj.isPassport && obj.validIdentificationType) {
			/* prettier-ignore */
			return <AppInput required type="text" name="passport" label="Passport" placeholder="e.g. VD1289281" disabled={obj.disabled} value={obj.values.passport} error={obj.errors.passport} touched={obj.touched.passport} onFormat={formatPassportString} onChange={obj.onChange} />
		}

		return null;
	}, []);


	const handleFilesChange = useCallback((files) => {
		formik.setFieldValue('files', files);
  		dispatch(setTempFiles(files)); // Save files to Redux state
		//console.log("store", manual.tempFiles);
		//console.log("value", formik.values.files);

	  }, [formik, dispatch]);


	const exampleFilesLabel = 
	[
		"Vehicle Registration Card",
		"Existing Vehicle Insurance Policy",
		"SSM",
		"Form 49",
		"Vehicle Photos",
	];

	return (
		<div className="page-manual-enquiry">
			<div className="enquiry">
				<form className="enquiry__form" onSubmit={formik.handleSubmit}>
					<div className="enquiry__container">
						<div className="enquiry__box">
							{!messageType && !formik.isSubmitting && (
								<div className="enquiry__wrapper">
									<h1 className="enquiry__title">Motor Insurance Enquiry</h1>
									<p className="enquiry__description">Send us an enquiry and we will come back to you!</p>

									<div className="enquiry__box-body">
										{/* prettier-ignore */}
										<AppSelectInput 
											required 
											type="text" 
											name="category" 
											label="Category" 
											placeholder="Please Select" 
											loadOptions={getEntityListing} 
											value={formik.values.category} 
											error={formik.errors.category} 
											touched={formik.touched.category} 
											onChange={formik.handleChange} 
											disabled={formik.isSubmitting || ['APermit', 'CPermit', 'SpecialType'].includes(type)}
											searchable={false} 
										/>
										
										{/* Conditionally render content based on the selected value */}
      									{formik.values.category === COMMON.CATEGORY.COMPANY && (
      									  <div className="enquiry__box-body__company">
      									    {/* prettier-ignore */}
											<AppInput type="text" required={formik.values.category === COMMON.CATEGORY.COMPANY} maxLength={100} name="companyName" label= "Company Name" placeholder="eg: ET Sdn Bhd" value={formik.values.companyName} error={formik.errors?.companyName} touched={formik.touched.companyName} onChange={formik.handleChange} />
											
											{/* prettier-ignore */}
											<AppInput type="text" required={formik.values.category === COMMON.CATEGORY.COMPANY} maxLength={100} name="companyReg" label= "Company Register ID" placeholder="eg: ABC123-123" value={formik.values.companyReg} error={formik.errors?.companyReg} touched={formik.touched.companyReg} onChange={formik.handleChange} />
											
      									  </div>
      									)}

										{/* Conditionally render content based on the selected value */}
										{formik.values.category === COMMON.CATEGORY.INDIVIDUAL && (
      									  <div className="enquiry__box-body__individual">
      									    {/* prettier-ignore */}
											<AppInput required={formik.values.category === COMMON.CATEGORY.INDIVIDUAL} maxLength={100} type="text" name="fullName" label= "Full Name" placeholder="eg: John Smith" value={formik.values.fullName} touched={formik.touched.fullName} error={formik.errors?.fullName} onChange={formik.handleChange} />

											{/* prettier-ignore */}
											<AppSelectInput required={formik.values.category === COMMON.CATEGORY.INDIVIDUAL} type="text" name="identificationType" label="ID Type" placeholder="Please Select" loadOptions={getIdentificationListing} value={formik.values.identificationType} error={formik.errors.identificationType} touched={formik.touched.identificationType} onChange={formik.handleChange} disabled={formik.isSubmitting} searchable={false} />

											{/* prettier-ignore */}
											<IdentificationField required={formik.values.category === COMMON.CATEGORY.INDIVIDUAL} values={formik.values} errors={formik.errors} touched={formik.touched} onChange={formik.handleChange} disabled={formik.isSubmitting} isPassport={isPassport} validIdentificationType={validIdentificationType} />
											
											{/* prettier-ignore */}
											<AppSelectInput required type="text" name="marital" label="Marital Status" placeholder="Please Select" loadOptions={getMaritalListing} value={formik.values.marital} error={formik.errors.marital} touched={formik.touched.marital} onChange={formik.handleChange} disabled={formik.isSubmitting} searchable={false} />
										
										  </div>
      									)}
										
										
										{/* prettier-ignore */}
										<AppMobileInput required type="number" maxLength={10} name="phoneNumber" prefixNoName="prefixNo" label="Mobile No" placeholder="e.g. 121231234" value={formik.values.phoneNumber} prefixNo={formik.values.prefixNo} error={formik.errors?.phoneNumber} touched={formik.touched?.phoneNumber} disabled={formik.isSubmitting} onChange={formik?.handleChange} onChangeCode={formik.setFieldValue} />
										
										{/* prettier-ignore */}
										<AppInput required type="text" maxLength={100} name="email" label="Email Address" placeholder="e.g. username@email.com" value={formik.values.email} touched={formik.touched.email} error={formik.errors.email} onChange={formik.handleChange} disabled={formik.isSubmitting} />


										{/* prettier-ignore */}
										<AppInput required type="text" maxLength={20} name="vehicleRegNo" label="Vehicle Registration No." placeholder="e.g. WXY1234" value={formik.values.vehicleRegNo} touched={formik.touched.vehicleRegNo} error={formik.errors.vehicleRegNo} onChange={formik.handleChange} onFormat={formatVehicleString} disabled={formik.isSubmitting} />

										{/* prettier-ignore */}
										<AppInput required type="number" maxLength={5} name="postcode" label="Postcode" placeholder="e.g. 57000" value={formik.values.postcode} touched={formik.touched.postcode} error={formik.errors.postcode} onChange={formik.handleChange} disabled={formik.isSubmitting} />

										{/* prettier-ignore */}
										<FileUpload maxFiles={5} sampleFiles={exampleFilesLabel} label="Upload Supporting Documents" touched={formik.touched.files} error={formik.errors.files} onFilesChange={handleFilesChange} files={formik.values.files} />

										{/* prettier-ignore */}
										<AppCheckbox name="takaful" onClick={onHandleTakaful} label={<TakafulLabel />} value={formik.values.takaful} touched={formik.touched.takaful} disabled={formik.isSubmitting} />

										{/* prettier-ignore */}
										<AppCheckbox required name="tnc" onClick={onHandleTnc} label={<TncLabel />} error={formik.errors.tnc} value={formik.values.tnc} touched={formik.touched.tnc} disabled={formik.isSubmitting} />

										{/* prettier-ignore */}
										<AppCheckbox required name="privacy" onClick={onHandlePrivacy} label={<PrivacyLabel />} error={formik.errors.privacy} value={formik.values.privacy} touched={formik.touched.privacy} disabled={formik.isSubmitting} />
									</div>

									<div className="enquiry__button-container">
										<AppButton type="submit" label="Submit" disabled={formik.isSubmitting} />
									</div>
								</div>
							)}

							<AppLoading isLoading={formik.isSubmitting} text="Processing page during quotation selection page - We're currently fetching your quotation." />

							<AppCardStatus messageType={messageType} error={errorCode} setMessageType={setMessageType} />
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default PageManualEnquiry;
